import React from "react";
import { Routes, Route } from "react-router-dom";
import Formulario from "./Formulario";
import FormReceived from "./form-received/FormReceived";
import AdminLogin from "./admin/AdminLogin";
import AdminDashboard from "./admin/AdminDashboard";
import RequireAuth from "./components/RequireAuth";
function App() {
  return (
    <Routes>
      <Route path="/" element={<Formulario />} />
      <Route path="/form-received" element={<FormReceived />} />
      <Route path="/admin" element={<AdminLogin />} />
      <Route
        path="/admin/dashboard"
        element={
          <RequireAuth>
            <AdminDashboard />
          </RequireAuth>
        }
      />
    </Routes>
  );
}

export default App;
