import {
  Box,
  Button,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
  Paper,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import Header from "../header/header";
import Footer from "../footer/footer";
import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../apis/firebaseConfig"; // ajusta el path si es necesario
import { useNavigate } from "react-router-dom"; // si quieres redirigir al dashboard

const AdminLogin = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Correo inválido")
        .required("Este campo es obligatorio"),
      password: Yup.string().required("Este campo es obligatorio"),
    }),
    onSubmit: async (values, { setSubmitting, setErrors }) => {
      try {
        const { email, password } = values;
        await signInWithEmailAndPassword(auth, email, password);
        console.log("Inicio de sesión exitoso");
        navigate("/admin/dashboard"); // redirige a donde tú quieras
      } catch (error) {
        console.error("Error al iniciar sesión:", error.message);
        setErrors({ password: t("admin.invalidCredentials") }); // o usa un toast o modal
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <Box
      bgcolor="#f9f9f9"
      minHeight="100vh"
      display="flex"
      flexDirection="column"
    >
      <Header />

      <Box
        flex={1}
        display="flex"
        justifyContent="center"
        alignItems="center"
        p={2}
      >
        <Paper
          elevation={3}
          sx={{
            width: "100%",
            maxWidth: 400,
            borderRadius: 4,
            p: 4,
            bgcolor: "white",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="h5"
            fontWeight={600}
            textAlign="center"
            fontFamily="'Montserrat', sans-serif"
            mb={3}
          >
            {t("admin.loginTitle") || "Inicio de sesión de administrador"}
          </Typography>
          <form onSubmit={formik.handleSubmit} noValidate>
            <TextField
              fullWidth
              label={t("admin.email") || "Correo electrónico"}
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              margin="normal"
            />

            <TextField
              fullWidth
              label={t("admin.password") || "Contraseña"}
              name="password"
              type={showPassword ? "text" : "password"}
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              margin="normal"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <Button
              type="submit"
              variant="contained"
              fullWidth
              sx={{
                mt: 3,
                height: "48px",
                backgroundColor: "#21386A",
                fontFamily: "'Montserrat', sans-serif",
                fontWeight: "600",
                textTransform: "none",
                "&:hover": {
                  backgroundColor: "#1a2f58",
                },
              }}
            >
              {t("admin.loginButton") || "Iniciar sesión"}
            </Button>
          </form>
        </Paper>
      </Box>

      <Footer />
    </Box>
  );
};

export default AdminLogin;
