import { Box, Button, Typography, Menu, MenuItem } from "@mui/material";
import "../index.css";
import LanguageIcon from "@mui/icons-material/Language";
import { useTranslation } from "react-i18next";
import { useState } from "react";

const Header = () => {
  const { t, i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);

  const currentLanguage = i18n.language === "es" ? "ES" : "EN";

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (lng) => {
    if (lng) i18n.changeLanguage(lng);
    setAnchorEl(null);
  };

  return (
    <Box
      sx={{
        backgroundColor: "#21386A",
        color: "white",
        padding: "10px 15px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        top: 0,
        left: 0,
        zIndex: 10,
      }}
    >
      <Box sx={{ flex: 1 }}></Box>

      <Typography
        variant="h6"
        sx={{
          fontFamily: "'Montserrat', sans-serif",
          fontSize: "14px",
          fontWeight: 700,
          lineHeight: "17px",
          textAlign: "center",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {t("header.welcome")}
        <Box
          component="img"
          src="/logo.svg"
          alt="GomiGuide Logo"
          sx={{ height: "50px" }}
        />
      </Typography>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
          gap: "1px",
          flex: 1,
          justifyContent: "center",
          outline: "none",
        }}
      >
        <Button
          variant="outlined"
          onClick={handleClick}
          sx={{
            maxWidth: "70px",
            backgroundColor: "#fff",
            color: "#111F46",
            textTransform: "none",
            borderRadius: "10px",
            fontWeight: "400",
            fontFamily: "'Montserrat', sans-serif",
            fontSize: "20px",
            px: 2,
            boxShadow: "0px 2px 4px rgba(0,0,0,0.1)",
            "&:hover": {
              backgroundColor: "#f5f5f5",
              boxShadow: "0px 3px 6px rgba(0,0,0,0.15)",
            },
            "& .MuiButton-startIcon": {
              marginRight: 0,
              gap: "21px !important",
            },
            border: "1px solid #4C4C4C",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
            <LanguageIcon
              sx={{ fontSize: "25px !important", marginRight: 0 }}
            />
            {currentLanguage}
          </Box>
        </Button>

        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => handleClose(null)}
        >
          <MenuItem onClick={() => handleClose("en")}>English</MenuItem>
          <MenuItem onClick={() => handleClose("es")}>Español</MenuItem>
        </Menu>
      </Box>
    </Box>
  );
};

export default Header;
