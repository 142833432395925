// AdminDashboard.js
import React, { useEffect, useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Avatar,
  Divider,
  Tooltip,
  Modal,
  Button,
  TablePagination,
} from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import DashboardIcon from "@mui/icons-material/Dashboard";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CommentIcon from "@mui/icons-material/Comment";
import { onValue, ref, update, remove } from "firebase/database";
import { database } from "../apis/firebaseConfig";
import { getAuth, signOut } from "firebase/auth";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import customIconUrl from "../assets/Puntero-map.svg"; // ajusta la ruta si está en otro lado
import MenuIcon from "@mui/icons-material/Menu";
import { useMediaQuery } from "@mui/material";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const drawerWidth = 200;

const AdminDashboard = () => {
  const [data, setData] = useState([]);
  const [mapOpen, setMapOpen] = useState(false);
  const [commentOpen, setCommentOpen] = useState(false);
  const [selectedEntry, setSelectedEntry] = useState(null);
  const [email, setEmail] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [mobileOpen, setMobileOpen] = useState(false);
  const isMobile = useMediaQuery("(max-width:768px)");

  const toggleDrawer = () => setMobileOpen(!mobileOpen);

  const customMarkerIcon = new L.Icon({
    iconUrl: customIconUrl,
    iconSize: [44, 46], // como en tu formulario
    iconAnchor: [17, 46], // para que apunte justo donde cae
  });

  useEffect(() => {
    const auth = getAuth();
    const unsubscribeAuth = auth.onAuthStateChanged((user) => {
      if (user) setEmail(user.email);
    });

    const gomiRef = ref(database, "gomiLocations");
    const unsubscribeDB = onValue(gomiRef, (snapshot) => {
      const values = snapshot.val();
      const formatted = values
        ? Object.entries(values)
            .filter(([_, value]) => value.state === 0)
            .map(([key, value]) => ({ id: key, ...value }))
        : [];
      setData(formatted);
    });

    return () => {
      unsubscribeAuth();
      unsubscribeDB();
    };
  }, []);

  const handleLogout = () => {
    const auth = getAuth();
    signOut(auth).then(() => {
      window.location.href = "/admin";
    });
  };

  const handleApprove = (id) => {
    const itemRef = ref(database, `gomiLocations/${id}`);
    update(itemRef, { state: 1 });
  };

  const handleDelete = (id) => {
    const itemRef = ref(database, `gomiLocations/${id}`);
    remove(itemRef);
  };

  const handleChangePage = (event, newPage) => setPage(newPage);
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <Drawer
        variant={isMobile ? "temporary" : "permanent"}
        open={isMobile ? mobileOpen : true}
        onClose={toggleDrawer}
        ModalProps={{ keepMounted: true }} // mejora el rendimiento en mobile
        sx={{
          width: isMobile ? 240 : drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: drawerWidth,
            boxSizing: "border-box",
            backgroundColor: "#21386A",
            color: "white",
          },
        }}
      >
        <Toolbar>
          <Box
            component="img"
            src="/logo.svg"
            alt="GomiGuide Logo"
            sx={{ height: "50px", my: 3 }}
          />
        </Toolbar>
        <Divider sx={{ mt: 0, mb: 2, backgroundColor: "white", mx: 2 }} />
        <List>
          <ListItem button>
            <ListItemIcon sx={{ color: "white" }}>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItem>
        </List>
      </Drawer>

      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <AppBar position="static" color="inherit" elevation={0}>
          <Toolbar sx={{ justifyContent: "space-between" }}>
            <Box display="flex" alignItems="center" gap={2}>
              {isMobile && (
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={toggleDrawer}
                  sx={{ mr: 2 }}
                >
                  <MenuIcon />
                </IconButton>
              )}
            </Box>

            <Box display="flex" alignItems="center" gap={2}>
              <Typography>{email}</Typography>
              <Avatar alt="Admin" src="/admin-profile.png" />
              <IconButton onClick={handleLogout}>
                <LogoutIcon />
              </IconButton>
            </Box>
          </Toolbar>
        </AppBar>

        <Typography variant="h5" mt={2} mb={2}>
          Registered Locations
        </Typography>
        <Box sx={{ overflowX: "auto" }}>
          {!isMobile ? (
            <TableContainer component={Paper}>
              <Table>
                <TableHead sx={{ backgroundColor: "#21386A" }}>
                  <TableRow>
                    <TableCell sx={{ color: "white" }}>Email</TableCell>
                    <TableCell sx={{ color: "white" }}>Alias</TableCell>
                    <TableCell sx={{ color: "white" }}>User Type</TableCell>
                    <TableCell sx={{ color: "white" }}>Country</TableCell>
                    <TableCell sx={{ color: "white" }}>
                      Container Type
                    </TableCell>
                    <TableCell sx={{ color: "white" }}>
                      Container Size
                    </TableCell>
                    <TableCell sx={{ color: "white" }}>
                      Container State
                    </TableCell>
                    <TableCell sx={{ color: "white" }}>Location</TableCell>
                    <TableCell sx={{ color: "white" }}>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((entry) => (
                      <TableRow key={entry.id}>
                        <TableCell>{entry.email}</TableCell>
                        <TableCell>{entry.alias}</TableCell>
                        <TableCell>
                          {entry.isTourist ? "Tourist" : "Resident"}
                        </TableCell>
                        <TableCell>{entry.country}</TableCell>
                        <TableCell>{entry.containerType}</TableCell>
                        <TableCell>{entry.containerSize}</TableCell>
                        <TableCell>{entry.containerState}</TableCell>
                        <TableCell>
                          <Button
                            onClick={() => {
                              setMapOpen(true);
                              setSelectedEntry(entry);
                            }}
                          >
                            View Map
                          </Button>
                        </TableCell>
                        <TableCell>
                          <Tooltip title="Approve">
                            <IconButton
                              onClick={() => handleApprove(entry.id)}
                              color="success"
                            >
                              <CheckCircleIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="View comment">
                            <IconButton
                              onClick={() => {
                                setCommentOpen(true);
                                setSelectedEntry(entry);
                              }}
                              color="primary"
                            >
                              <CommentIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Delete">
                            <IconButton
                              onClick={() => handleDelete(entry.id)}
                              color="error"
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))}
                  {data.length === 0 && (
                    <TableRow>
                      <TableCell colSpan={9} align="center">
                        No hay registros aún.
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>

              <TablePagination
                component="div"
                count={data.length}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={[5, 10, 25]}
              />
            </TableContainer>
          ) : (
            <Box>
              {data
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((entry) => (
                  <Accordion
                    key={entry.id}
                    sx={{ mb: 1, borderRadius: "12px" }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={`panel-${entry.id}-content`}
                      id={`panel-${entry.id}-header`}
                      sx={{
                        bgcolor: "#21386A",
                        borderRadius: "12px",
                        "& .MuiAccordionSummary-content": {
                          display: "flex",
                          justifyContent: "space-between",
                        },
                      }}
                    >
                      <Typography fontWeight={600} sx={{ color: "white" }}>
                        {entry.isTourist ? "Tourist" : "Resident"}
                      </Typography>
                      <Typography fontSize="14px" color="white">
                        {entry.email}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails
                      sx={{ bgcolor: "white", borderRadius: "0 0 12px 12px" }}
                    >
                      <Typography>
                        <b>Alias:</b> {entry.alias}
                      </Typography>
                      <Typography>
                        <b>Country:</b> {entry.country}
                      </Typography>
                      <Typography>
                        <b>Container Type:</b> {entry.containerType}
                      </Typography>
                      <Typography>
                        <b>Container Size:</b> {entry.containerSize}
                      </Typography>
                      <Typography>
                        <b>Container State:</b> {entry.containerState}
                      </Typography>
                      <Typography>
                        <b>Comment:</b> {entry.comment || "N/A"}
                      </Typography>
                      <Box
                        display="flex"
                        justifyContent="flex-end"
                        gap={1}
                        mt={2}
                      >
                        <Button
                          size="small"
                          onClick={() => {
                            setMapOpen(true);
                            setSelectedEntry(entry);
                          }}
                        >
                          View map
                        </Button>
                        <IconButton
                          onClick={() => handleApprove(entry.id)}
                          color="success"
                        >
                          <CheckCircleIcon />
                        </IconButton>

                        <IconButton
                          onClick={() => handleDelete(entry.id)}
                          color="error"
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                ))}

              <Box
                sx={{
                  mt: { xs: 0, md: 2 },
                  position: { xs: "fixed", md: "static" },
                  bottom: { xs: 0, md: "auto" },
                  left: 0,
                  width: "100%",
                  zIndex: 1100,
                  backgroundColor: "#f9f9f9",
                  borderTop: "1px solid #e0e0e0",
                  px: 2,
                  py: 1,
                }}
              >
                <TablePagination
                  component="div"
                  count={data.length}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  rowsPerPageOptions={[5, 10, 25]}
                />
              </Box>
            </Box>
          )}
        </Box>

        {/* Modal Map */}
        <Modal open={mapOpen} onClose={() => setMapOpen(false)}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              bgcolor: "white",
              p: 2,
              width: isMobile ? "90%" : 600,
              height: 500,
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            {selectedEntry?.location && (
              <MapContainer
                center={[
                  selectedEntry.location.lat,
                  selectedEntry.location.lng,
                ]}
                zoom={15}
                scrollWheelZoom={true}
                style={{ height: "100%", width: "100%" }}
              >
                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                <Marker
                  position={[
                    selectedEntry.location.lat,
                    selectedEntry.location.lng,
                  ]}
                  icon={customMarkerIcon}
                >
                  <Popup>
                    lat: {selectedEntry.location.lat}
                    <br></br>
                    lng: {selectedEntry.location.lng}
                  </Popup>
                </Marker>
              </MapContainer>
            )}
            <Box display="flex" justifyContent="flex-end">
              <Button
                onClick={() => setMapOpen(false)}
                sx={{ bgcolor: "#f0f0f0", color: "black" }}
              >
                SALIR
              </Button>
            </Box>
          </Box>
        </Modal>

        {/* Modal Comment */}
        <Modal open={commentOpen} onClose={() => setCommentOpen(false)}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              bgcolor: "white",
              p: 3,
              width: 400,
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              Comentario
            </Typography>
            <Typography>
              {selectedEntry?.comment || "Without comment"}
            </Typography>
            <Box display="flex" justifyContent="flex-end">
              <Button
                onClick={() => setCommentOpen(false)}
                sx={{ bgcolor: "#f0f0f0", color: "black" }}
              >
                SALIR
              </Button>
            </Box>
          </Box>
        </Modal>
      </Box>
    </Box>
  );
};

export default AdminDashboard;
