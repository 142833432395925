// src/firebaseConfig.js
import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCWQLOBisx6yD2PAEW0XJ-JVlM7sSKAmps",
  authDomain: "whereisdumpsterfirebase.firebaseapp.com",
  projectId: "whereisdumpsterfirebase",
  storageBucket: "whereisdumpsterfirebase.firebasestorage.app",
  messagingSenderId: "1055423851126",
  appId: "1:1055423851126:web:173b23a9e46e224c6a0d57",
};

const app = initializeApp(firebaseConfig);
const database = getDatabase(app);
const auth = getAuth(app);

export { database, auth };
