import { Box, Button, Typography } from "@mui/material";
import "../index.css";
import Header from "../header/header";
import Footer from "../footer/footer";
import { useTranslation } from "react-i18next";
import React, { useState, useEffect, useRef } from "react";

const FormReceived = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [isTablet, setTablet] = useState(
    window.innerWidth > 768 && window.innerWidth < 1024
  );

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
      setTablet(window.innerWidth > 768 && window.innerWidth < 1024);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const { t } = useTranslation();
  return (
    <Box
      bgcolor="white"
      sx={{
        width: "100%",
        overflowX: "hidden",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        minHeight: "100vh",
      }}
    >
      {/* Header */}
      <Header />
      {/* Main Content */}
      <Box
        sx={{
          flex: 1, // Ocupa todo el espacio disponible entre el header y el footer
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {/* imagen con texto */}
        <Box
          sx={{
            backgroundImage: isMobile
              ? 'url("/form-received-bg.svg")'
              : 'url("/form-received-bg-desktop.svg")',
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            minHeight: isMobile ? "520px" : isTablet ? "750px" : "950px",
            backgroundPosition: isMobile
              ? "center"
              : isTablet
                ? "center"
                : "center",
            width: "100%",
            color: "white",
            textAlign: "center",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
          }}
        >
          {/* Notificación flotante */}
          <Box
            sx={{
              position: "absolute",
              top: "30%",
              left: "50%",
              width: "100%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "rgba(255, 255, 255, 0.3)", // 👈 bajamos de 0.6 a 0.3
              padding: "16px",
              boxShadow: "0px 4px 12px rgba(0,0,0,0.1)",
              display: "flex",
              alignItems: "center",
              gap: "16px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "row",
                width: "100%",
              }}
            >
              <Box
                component="img"
                src="/dumpster-icon.svg"
                alt="Formulario recibido"
                sx={{
                  filter: "drop-shadow(3px 3px 6px rgba(0, 0, 0, 0.5))", // 👈 más contraste en la sombra
                  width: isMobile ? "86px" : "132px",
                  height: isMobile ? "110px" : "184px",
                  mr: 2,
                }}
              />
              <Box>
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontWeight: 700,
                    fontSize: isMobile ? "16px" : "40px",
                    color: "black",
                    fontFamily: "'Fredoka One', sans-serif",
                    lineHeight: isMobile ? "25px" : "100%",
                    letterSpacing: "1px",
                    textAlign: "justify",
                  }}
                >
                  {t("receivedPage.title")}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    fontFamily: "'Montserrat', sans-serif",
                    lineHeight: "100%",
                    fontSize: isMobile ? "10px" : "24px",
                    color: "black",
                    textAlign: "justify",
                  }}
                >
                  {t("receivedPage.description")}
                  <br />
                  {t("receivedPage.gratitude")}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      {/* Footer */}
      <Footer sx={{ mt: "0px !important" }} />
    </Box>
  );
};

export default FormReceived;
