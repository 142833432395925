import { Box, Typography, Link, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import React, { useState, useEffect, useRef } from "react";
import "../index.css";

const Footer = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isMobileFooter, setIsMobileFooter] = useState(
    window.innerWidth >= 768
  );

  const [isTablet, setTablet] = useState(
    window.innerWidth >= 768 && window.innerWidth < 1024
  );

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
      setIsMobileFooter(window.innerWidth >= 768);
      setTablet(window.innerWidth > 768 && window.innerWidth < 1024);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const { t } = useTranslation();
  return (
    <Box component="footer">
      {/* Footer verde */}

      {isMobile ? (
        <Box
          sx={{
            backgroundColor: "#61B73F",
            color: "white",
            px: 3,
            pt: 4,
            pb: 0,
            textAlign: "left",
            fontFamily: "'Fredoka One', sans-serif !important",
          }}
        >
          {/* Parte Texto y Logo */}
          <Grid
            container
            spacing={2}
            sx={{ display: "flex", flexDirection: "row" }}
          >
            <Grid item xs={6} sm={7}>
              <Typography
                sx={{
                  fontWeight: "400",
                  fontSize: "24px",
                  fontFamily: "'Fredoka One', sans-serif !important",
                  letterSpacing: "0",
                }}
              >
                {t("footer.title")}
              </Typography>
              <Typography
                sx={{
                  mt: 0,
                  maxWidth: "175px",
                  fontFamily: "'Montserrat', sans-serif",
                  fontSize: "12px",
                  lineHeight: "16px",
                  letterSpacing: "0",
                }}
              >
                {t("footer.description")}
              </Typography>
            </Grid>

            <Grid
              item
              xs={6}
              sm={5}
              sx={{
                paddingTop: "20px !important", // o pt: 2.5,
                display: "flex",
                justifyContent: isMobileFooter
                  ? "flex-end"
                  : isMobile
                    ? "center"
                    : "center",
              }}
            >
              <Box sx={{ display: "flex", gap: 1, justifyContent: "center" }}>
                <img src="/footer-logo.svg" alt="Gomi Guide Logo" width="140" />
              </Box>
            </Grid>
          </Grid>
          {/* Parte Email e iconos */}
          <Grid
            container
            spacing={2}
            sx={{ display: "flex", flexDirection: "row" }}
          >
            <Grid item xs={6} sm={7}>
              <Box
                sx={{
                  mt: 4,
                  backgroundColor: "#D5EFC8",
                  px: 4,
                  py: 1,

                  display: "inline-block",
                }}
              >
                <Typography
                  variant="caption"
                  sx={{
                    fontFamily: "'Montserrat', sans-serif",
                    fontSize: "11px",
                    fontWeight: "700",
                    lineHeight: "16px",
                    letterSpacing: "0",
                    color: "#282938",
                  }}
                >
                  {t("footer.email")}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    fontFamily: "'Montserrat', sans-serif",
                    fontSize: "11px",
                    fontWeight: "500",
                    lineHeight: "16px",
                    letterSpacing: "0",
                    color: "#282938",
                  }}
                >
                  hello@gomiguide.com
                </Typography>
              </Box>
            </Grid>

            <Grid
              item
              xs={6}
              sm={5}
              sx={{
                paddingTop: "12px !important", // o pt: 2.5,
                display: "flex",
                flexDirection: "column",
                alignItems: isMobileFooter
                  ? "flex-end"
                  : isMobile
                    ? "center"
                    : "center",
                paddingRight: isMobileFooter
                  ? "10px"
                  : isMobile
                    ? "0px"
                    : "0px",
              }}
            >
              <Box
                sx={{
                  mt: 5,
                  display: "flex",
                  gap: 1,
                  justifyContent: "center",
                }}
              >
                <Link href="#" color="inherit">
                  <img src="/facebook-icon.svg" alt="facebook" width="24" />
                </Link>
                <Link href="#" color="inherit">
                  <img src="/x-icon.svg" alt="facebook" width="24" />
                </Link>
                <Link href="#" color="inherit">
                  <img src="/instagram-icon.svg" alt="facebook" width="24" />
                </Link>
              </Box>
            </Grid>
          </Grid>
        </Box>
      ) : (
        <Box
          sx={{
            backgroundColor: "#61B73F",
            color: "white",
            px: isMobile ? 7 : isTablet ? 10 : 14,
            pt: 0,
            pb: 0,
            textAlign: "left",
            fontFamily: "'Fredoka One', sans-serif !important",
          }}
        >
          {/* Parte Texto y Logo */}
          <Grid
            container
            spacing={0}
            sx={{ display: "flex", flexDirection: "row" }}
          >
            <Grid item sm={4} sx={{ pt: 2 }}>
              <Typography
                sx={{
                  fontWeight: "400",
                  fontSize: "24px",
                  fontFamily: "'Fredoka One', sans-serif !important",
                  letterSpacing: "0",
                }}
              >
                {t("footer.title")}
              </Typography>
              <Typography
                sx={{
                  mt: 0,
                  maxWidth: "175px",
                  fontFamily: "'Montserrat', sans-serif",
                  fontSize: "12px",
                  lineHeight: "16px",
                  letterSpacing: "0",
                }}
              >
                {t("footer.description")}
              </Typography>
            </Grid>
            <Grid
              item
              sm={4}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  backgroundColor: "#D5EFC8",
                  px: 4,
                  py: 1,

                  display: "inline-block",
                }}
              >
                <Typography
                  variant="caption"
                  sx={{
                    fontFamily: "'Montserrat', sans-serif",
                    fontSize: "11px",
                    fontWeight: "700",
                    lineHeight: "16px",
                    letterSpacing: "0",
                    color: "#282938",
                  }}
                >
                  {t("footer.email")}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    fontFamily: "'Montserrat', sans-serif",
                    fontSize: "11px",
                    fontWeight: "500",
                    lineHeight: "16px",
                    letterSpacing: "0",
                    color: "#282938",
                  }}
                >
                  hello@gomiguide.com
                </Typography>
              </Box>
              <Box
                sx={{
                  my: 3,
                  display: "flex",
                  gap: 1,
                  justifyContent: "center",
                }}
              >
                <Link href="#" color="inherit">
                  <img src="/facebook-icon.svg" alt="facebook" width="24" />
                </Link>
                <Link href="#" color="inherit">
                  <img src="/x-icon.svg" alt="facebook" width="24" />
                </Link>
                <Link href="#" color="inherit">
                  <img src="/instagram-icon.svg" alt="facebook" width="24" />
                </Link>
              </Box>
            </Grid>
            <Grid
              item
              sm={4}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                pt: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: 1,
                  justifyContent: "center",
                  alignItems: "flex-start",
                }}
              >
                <img src="/footer-logo.svg" alt="Gomi Guide Logo" width="140" />
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}

      {/* Parte inferior blanca */}
      <Box
        sx={{
          backgroundColor: "#fff",
          color: "#000",
          textAlign: "center",
          pt: 2,
          pb: 4,
        }}
      >
        <Box
          sx={{
            mt: 0,
            display: "flex",

            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: 0,
            flexWrap: "wrap",
            fontFamily: "'Montserrat', sans-serif",
            "& .MuiTypography-root": {
              fontFamily: "'Montserrat', sans-serif",
              fontSize: "12px",
              lineHeight: "28px",
              letterSpacing: "0",
              color: "black",
              fontWeight: isMobile ? "500" : "bold",
            },
            "& a": {
              fontFamily: "'Montserrat', sans-serif",
              fontSize: "12px",
              lineHeight: "28px",
              letterSpacing: "0",
              color: "#282938",
              textDecoration: "none",
              "&:hover": {
                textDecoration: "underline",
              },
              fontWeight: isMobile ? "400" : "bold",
            },
          }}
        >
          <Typography>
            © {new Date().getFullYear()} Gomi Guide. {t("footer.copyRight")}
          </Typography>
          <Link href="#" underline="hover" color="inherit">
            {t("footer.privacyPolicy")}
          </Link>
          <Link href="#" underline="hover" color="inherit">
            {t("footer.useTerm")}
          </Link>
          <Link href="#" underline="hover" color="inherit">
            {t("footer.cookiesPolicy")}
          </Link>
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;
