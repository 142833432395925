import React, { useState, useEffect, useRef } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "@mui/material";
import { ref, push } from "firebase/database";
import { database } from "./apis/firebaseConfig";

import {
  Box,
  Button,
  Modal,
  TextField,
  MenuItem,
  Typography,
  Select,
  FormControl,
  InputLabel,
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormLabel,
  Divider,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { MapContainer, TileLayer, Marker, useMapEvents } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";

import SendIcon from "@mui/icons-material/Send";
import Footer from "./footer/footer";
import Header from "./header/header";
import { countries } from "./countries/countries";
import "leaflet/dist/leaflet.css";
import L, { divIcon } from "leaflet";
import markerIcon2x from "leaflet/dist/images/marker-icon-2x.png";
import markerIcon from "leaflet/dist/images/marker-icon.png";
import markerShadow from "leaflet/dist/images/marker-shadow.png";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import AddIcon from "@mui/icons-material/Add";
import customIconUrl from "./assets/Puntero-map.svg";

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: markerIcon2x,
  iconUrl: markerIcon,
  shadowUrl: markerShadow,
});

const customMarkerIcon = new L.Icon({
  iconUrl: customIconUrl,
  iconSize: [44, 46], // como el del diseño de Figma
  iconAnchor: [17, 36], // ancla en la base del ícono (la mitad del ancho, todo el alto)
});

const Formulario = () => {
  const saveFormData = (data) => {
    const formRef = ref(database, "gomiLocations"); // crea la colección/formulario
    push(formRef, data)
      .then(() => {
        console.log("Formulario guardado correctamente", data);
      })
      .catch((error) => {
        console.error("Error al guardar el formulario:", error);
      });
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isTablet, setTablet] = useState(
    window.innerWidth > 768 && window.innerWidth <= 1024
  );
  const { t } = useTranslation();
  const [userType, setUserType] = useState(null); // null, "tourist", o "resident"
  const [confirmOpen, setConfirmOpen] = useState(false);

  const [coordinates, setCoordinates] = useState({ lat: null, lng: null });
  const navigate = useNavigate();
  const [locationError, setLocationError] = useState(false);

  const [showMap, setShowMap] = useState(false);
  const mapRef = useRef(null);

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",

    width: isMobile ? "90%" : isTablet ? "50%" : "20%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setCoordinates({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });

          if (mapRef.current) {
            mapRef.current.setView([latitude, longitude], 13);
          }
        },
        (error) => {
          console.error("Error al obtener ubicación:", error);
        },
        {
          enableHighAccuracy: true, // 👈 clave
          timeout: 10000,
          maximumAge: 0,
        }
      );
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
      setTablet(window.innerWidth > 768 && window.innerWidth <= 1024);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const formik = useFormik({
    initialValues: {
      country: "",
      isTourist: "",
      state: 0,
      alias: "",
      email: "",
      containerType: "",
      containerSize: "",
      containerState: "",
      comment: "",
      acceptTerms: false,
      location: {
        lat: null,
        lng: null,
      },
    },

    validationSchema: Yup.object({
      isTourist: Yup.string().required(t("validation.selectOption")),
      country: Yup.string().required(t("validation.requiredField")),
      email: Yup.string()
        .email(t("validation.invalidEmail"))
        .required(t("validation.requiredField")),
      containerType: Yup.string().required(t("validation.requiredField")),
      containerSize: Yup.string().required(t("validation.requiredField")),
      containerState: Yup.string().required(t("validation.requiredField")),
      comment: Yup.string().max(150, t("validation.maxComment")),
      acceptTerms: Yup.boolean().oneOf([true], t("validation.acceptTerms")),
    }),

    onSubmit: (values) => {
      setConfirmOpen(true); // Mostrar el modal antes de enviar

      /*
      const payload = {
        ...values,
        coordinates,
      };
      fetch("test.com/api", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      })
        .then(() => navigate("/formulario-recibido"))
        .catch((err) => console.error(err));
    */
    },
  });
  const handleConfirmSend = () => {
    const values = formik.values; // 👈 agrega esta línea

    const payload = {
      ...values,
      isTourist: values.isTourist === "true",
      location: coordinates,
      createdAt: new Date().toISOString(),
    };

    console.log("JSON final:", payload);
    saveFormData(payload); // ✅ Aquí llamas a tu función para guardar en Firebase

    setConfirmOpen(false); // Cerrar modal
    navigate("/form-received");
  };
  const LocationMarker = ({ setCoordinates, coordinates }) => {
    const [position, setPosition] = useState(null);
    const [key, setKey] = useState(0);

    useEffect(() => {
      if (coordinates?.lat && coordinates?.lng) {
        setPosition([coordinates.lat, coordinates.lng]);
      }
    }, [coordinates]);

    useMapEvents({
      click(e) {
        const { lat, lng } = e.latlng;
        setPosition([lat, lng]);
        setCoordinates({ lat, lng });
        setKey(Date.now());
        setLocationError(false);
      },
    });

    const animatedIcon = L.divIcon({
      className: "",
      html: `<div class="marker-drop marker-${key}">
               <img src="/Puntero-map.svg" width="44" height="46" />
             </div>`,
      iconSize: [44, 46],
      iconAnchor: [17, 46],
    });

    return position ? <Marker position={position} icon={animatedIcon} /> : null;
  };

  const handleAgregar = () => {
    if (coordinates.lat && coordinates.lng) {
      formik.setFieldValue("location", coordinates); // ← Esto ya lo tienes bien
      setLocationError(false); // Oculta el mensaje de error
      setShowMap(false); // Cierra el mapa
    } else {
      setLocationError(true); // Muestra el mensaje de error
    }
  };

  const isFormReadyToSubmit = () => {
    const {
      isTourist,
      country,
      email,
      containerType,
      containerSize,
      containerState,
      acceptTerms,
      location,
    } = formik.values;

    const hasLocation = location.lat !== null && location.lng !== null;

    return (
      formik.isValid &&
      formik.dirty &&
      isTourist &&
      country &&
      email &&
      containerType &&
      containerSize &&
      containerState &&
      acceptTerms &&
      hasLocation
    );
  };

  return (
    <Box
      bgcolor="white"
      sx={{
        width: "100%",
        overflowX: "hidden",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "auto",
      }}
    >
      {/* Header */}
      <Header />
      {/* Main Content */}
      <Box
        sx={{
          flex: 1, // Ocupa todo el espacio disponible entre el header y el footer
          display: "flex",
          flexDirection: isMobile ? "column" : "column",
          alignItems: isMobile ? "center" : "center",
          justifyContent: "space-between",
          mb: 8,
        }}
      >
        {/* imagen con texto */}
        <Box
          sx={{
            backgroundImage: showMap
              ? 'url("header-background-map.svg")'
              : 'url("header-background.svg")',
            backgroundSize: "cover",
            backgroundPosition: isMobile ? "center" : "center -500px",
            backgroundRepeat: "no-repeat",
            height: isMobile ? "283px" : isTablet ? "1080px" : "730px",
            width: "100%",
            color: "white",
            textAlign: "center",

            position: "relative",
          }}
        >
          <Typography
            variant="subtitle1"
            sx={{
              fontFamily: "'Fredoka One', sans-serif",
              color: "black",
              width: isMobile ? "225px" : "481px",
              fontSize: isMobile ? "20px" : isTablet ? "30px" : "35px",
              textAlign: "left",
              lineHeight: isMobile ? "24px" : "100%",
              position: "absolute",
              fontWeight: "400",
              top: isMobile ? "20%" : isTablet ? "11%" : "15%",
              left: isMobile ? "12%" : isTablet ? "8%" : "22%",
            }}
          >
            {showMap ? t("header.mapMessage") : t("header.defaultMessage")}
          </Typography>
        </Box>

        {/* Formulario */}
        <Box
          className="form-container"
          p={2}
          sx={{
            width: "100%",

            position: "relative",

            padding: "0px",
          }}
        >
          <form
            onSubmit={formik.handleSubmit}
            className={showMap ? "form form-location" : "form form-location"}
          >
            {showMap ? (
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    mb: 2,
                  }}
                >
                  <Button
                    onClick={() => setShowMap(false)}
                    sx={{
                      minWidth: 0,
                      width: "26px",
                      height: "26px",
                      backgroundColor: "#111F46",
                      borderRadius: "8px",
                      padding: 0,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      color: "white",
                      boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.2)",
                      "&:hover": {
                        backgroundColor: "#1a2d5a",
                      },
                    }}
                  >
                    <ArrowBackIosNewIcon sx={{ fontSize: "16px" }} />
                  </Button>
                </Box>
                <Typography
                  className="input-titles"
                  sx={{
                    fontSize: isMobile
                      ? "12px"
                      : isTablet
                        ? "12px"
                        : "14px !important",
                  }}
                >
                  {t("form.mapInstructions")}
                </Typography>
                <Typography
                  className="input-instructions"
                  sx={{
                    mb: 2,
                    fontSize: isMobile
                      ? "12px"
                      : isTablet
                        ? "12px"
                        : "14px !important",
                  }}
                >
                  {t("form.mapStep1")}
                  <br />
                  {t("form.mapStep2")}
                </Typography>
                <MapContainer
                  center={
                    coordinates.lat
                      ? [coordinates.lat, coordinates.lng]
                      : [35.682839, 139.759455] // Tokio, Japón
                  }
                  zoom={13}
                  style={{ height: "600px", width: "100%" }}
                  whenCreated={(mapInstance) => {
                    mapRef.current = mapInstance;
                  }}
                >
                  <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                  <LocationMarker
                    setCoordinates={setCoordinates}
                    coordinates={coordinates}
                  />
                </MapContainer>
                <Divider sx={{ mt: 3, mb: 2 }} />
                <Box
                  sx={{
                    display: "flex",
                    gap: 2,
                    justifyContent: "center",
                    mt: 3,
                  }}
                >
                  {/* Botón Agregar */}
                  <Button
                    variant="contained"
                    onClick={handleAgregar}
                    sx={{
                      backgroundColor: "#21386A",
                      color: "white",
                      textTransform: "none",
                      fontSize: "18px",
                      fontFamily: "'Montserrat', sans-serif",
                      fontWeight: 500,
                      px: 3,
                      py: 1,
                      "&:hover": {
                        backgroundColor: "#21386A",
                      },

                      //anterior
                      height: "56px",
                      lineHeight: "56px",
                      letterSpacing: "1.2px",

                      border: "none",
                    }}
                  >
                    {t("form.add")}
                    <Box
                      sx={{
                        width: 24,
                        height: 24,
                        marginLeft: "7px",
                        borderRadius: "50%",
                        border: "2px solid white",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        fontWeight: "bold",
                        fontSize: "20px",
                        color: "white", // color del "+"
                      }}
                    >
                      <AddIcon
                        sx={{
                          fontSize: "18px",
                          fontWeight: 100,
                          color: "white",
                        }}
                      />
                    </Box>
                  </Button>

                  {/* Botón Cancelar */}
                  <Button
                    variant="outlined"
                    onClick={() => setShowMap(false)}
                    sx={{
                      border: "1.5px solid red",
                      color: "red",
                      textTransform: "none",
                      fontSize: "18px",
                      fontFamily: "'Montserrat', sans-serif",
                      fontWeight: 500,
                      px: 3,
                      py: 1,
                      borderRadius: "4px",
                      "&:hover": {
                        backgroundColor: "#ffe5e5",
                        borderColor: "red",
                      },
                    }}
                  >
                    {t("form.cancel")}
                  </Button>
                </Box>
              </Box>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: isMobile ? "column" : "row",
                  gap: isMobile ? 0 : 10,
                }}
              >
                <Box sx={{ flex: 1 }}>
                  <Typography className="input-titles">
                    {t("form.chooseIdentity")}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      margin: isMobile
                        ? "0 15px"
                        : isTablet
                          ? "0 25px"
                          : "0 45px",
                      mt: 2,
                      mb: 2,
                      gap: 0,
                    }}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={userType === "tourist"}
                          onChange={() => {
                            const newType =
                              userType === "tourist" ? null : "tourist";
                            setUserType(newType);
                            formik.setFieldValue(
                              "isTourist",
                              newType === "tourist" ? "true" : "false"
                            );
                          }}
                          icon={
                            <Box
                              sx={{
                                width: 20,
                                height: 20,
                                border: "2px solid #999",
                                borderRadius: "4px",
                              }}
                            />
                          }
                          checkedIcon={
                            <Box
                              sx={{
                                width: 20,
                                height: 20,
                                borderRadius: "4px",
                                backgroundColor: "#21386A",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                color: "white",
                                fontWeight: "bold",
                              }}
                            >
                              ✓
                            </Box>
                          }
                        />
                      }
                      label={t("form.tourist")}
                      sx={{
                        "& .MuiTypography-root": {
                          fontFamily: "'Montserrat', sans-serif",
                          fontSize: "13px",
                        },
                      }}
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={userType === "resident"}
                          onChange={() => {
                            const newType =
                              userType === "resident" ? null : "resident";
                            setUserType(newType);
                            formik.setFieldValue(
                              "isTourist",
                              newType === "tourist" ? "true" : "false"
                            );
                          }}
                          icon={
                            <Box
                              sx={{
                                width: 20,
                                height: 20,
                                border: "2px solid #999",
                                borderRadius: "4px",
                              }}
                            />
                          }
                          checkedIcon={
                            <Box
                              sx={{
                                width: 20,
                                height: 20,
                                borderRadius: "4px",
                                backgroundColor: "#21386A",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                color: "white",
                                fontWeight: "bold",
                              }}
                            >
                              ✓
                            </Box>
                          }
                        />
                      }
                      label={t("form.resident")}
                      sx={{
                        "& .MuiTypography-root": {
                          fontFamily: "'Montserrat', sans-serif",
                          fontSize: "13px",
                        },
                      }}
                    />
                  </Box>

                  <Divider
                    sx={{ mb: 2, display: isMobile ? "block" : "none" }}
                  />

                  <Typography className="input-titles">
                    {t("form.selectCountry")}
                  </Typography>
                  <FormControl fullWidth margin="normal">
                    <InputLabel id="country-label">
                      {t("form.country")}
                    </InputLabel>
                    <Select
                      labelId="country-label"
                      id="country"
                      name="country"
                      label={t("form.country")}
                      value={formik.values.country}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.country && Boolean(formik.errors.country)
                      }
                      sx={{
                        borderRadius: "10px",
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "10px",
                        },
                      }}
                    >
                      {countries.map((country) => (
                        <MenuItem key={country} value={country}>
                          {country}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <Typography className="input-titles" sx={{ mt: 1 }}>
                    {t("form.data")}
                  </Typography>

                  <TextField
                    fullWidth
                    label={t("form.alias")}
                    name="alias"
                    margin="normal"
                    value={formik.values.alias}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.alias && Boolean(formik.errors.alias)}
                    helperText={formik.touched.alias && formik.errors.alias}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "10px",
                      },
                    }}
                  />

                  <TextField
                    fullWidth
                    label={t("form.email")}
                    name="email"
                    margin="normal"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "10px",
                      },
                      mb: isMobile ? 0 : 2,
                    }}
                  />
                  <Divider
                    sx={{ mb: 2, mt: 2, display: isMobile ? "block" : "none" }}
                  />
                  <Typography className="input-titles">
                    {t("form.containerInfo")}
                  </Typography>

                  <FormControl fullWidth margin="normal">
                    <InputLabel>{t("form.containerType")}</InputLabel>
                    <Select
                      name="containerType"
                      label={t("form.containerType")}
                      value={formik.values.containerType}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.containerType &&
                        Boolean(formik.errors.containerType)
                      }
                      sx={{
                        borderRadius: "10px",
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "10px",
                        },
                      }}
                    >
                      <MenuItem value="general">General</MenuItem>
                    </Select>
                  </FormControl>

                  <FormControl fullWidth margin="normal">
                    <InputLabel>{t("form.containerSize")}</InputLabel>
                    <Select
                      name="containerSize"
                      label={t("form.containerSize")}
                      value={formik.values.containerSize}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.containerSize &&
                        Boolean(formik.errors.containerSize)
                      }
                      sx={{
                        borderRadius: "10px",
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "10px",
                        },
                      }}
                    >
                      <MenuItem value="small">
                        {t("containerSize.small")}
                      </MenuItem>
                      <MenuItem value="medium">
                        {t("containerSize.medium")}
                      </MenuItem>
                      <MenuItem value="big">{t("containerSize.big")}</MenuItem>
                    </Select>
                  </FormControl>
                  <Divider
                    sx={{ mb: 2, mt: 2, display: isMobile ? "block" : "none" }}
                  />
                </Box>
                <Box sx={{ flex: 1 }}>
                  {/*estado del contenerdor*/}
                  <Typography className="input-titles">
                    {t("form.containerState")}
                  </Typography>
                  <FormControl fullWidth margin="normal">
                    <InputLabel>{t("form.stateCondition")}</InputLabel>
                    <Select
                      name="containerState"
                      label={t("form.stateCondition")}
                      value={formik.values.containerState}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.containerState &&
                        Boolean(formik.errors.containerState)
                      }
                      sx={{
                        borderRadius: "10px",
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "10px",
                        },
                        mb: isMobile ? 0 : 2,
                      }}
                    >
                      <MenuItem value="bad">{t("containerState.bad")}</MenuItem>
                      <MenuItem value="good">
                        {t("containerState.good")}
                      </MenuItem>
                      <MenuItem value="excelent">
                        {t("containerState.excelent")}
                      </MenuItem>
                    </Select>
                  </FormControl>
                  <Divider
                    sx={{ mb: 2, mt: 2, display: isMobile ? "block" : "none" }}
                  />
                  <Typography className="input-titles">
                    {t("form.shareLocation")}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      mb: isMobile ? 0 : 2,
                    }}
                  >
                    <Button
                      variant="outlined"
                      onClick={() => {
                        setShowMap(true);
                        if (
                          !formik.values.location.lat ||
                          !formik.values.location.lng
                        ) {
                          setLocationError(true);
                        }
                      }}
                      sx={{
                        mt: 5,
                        mb: 5,
                        width: "250px",
                        height: "56px",
                        textTransform: "none",
                        fontFamily: "'Montserrat', sans-serif",
                        fontWeight: "500",
                        fontSize: "18px",
                        lineHeight: "56px",
                        letterSpacing: "1.2px",
                        backgroundColor: "#21386A",
                        color: "white",
                        ":hover": {
                          backgroundColor: "#21386A",
                        },
                        border: "none",
                      }}
                    >
                      {t("form.indicateOnMap")}
                    </Button>
                    {formik.values.location.lat ? (
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontFamily: "'Montserrat', sans-serif",
                          mt: -2,
                          textAlign: "center",
                          color: "#4caf50", // verde
                        }}
                      >
                        {t("form.locationSaved")}
                      </Typography>
                    ) : locationError ? (
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontFamily: "'Montserrat', sans-serif",
                          mt: isMobile ? -2 : -4,
                          textAlign: "center",
                          color: "#f44336", // rojo
                        }}
                      >
                        {t("form.locationRequired")}
                      </Typography>
                    ) : null}
                  </Box>

                  <Divider
                    sx={{ mb: 2, mt: 2, display: isMobile ? "block" : "none" }}
                  />
                  <Typography className="input-titles">
                    {t("form.commentLabel")}
                  </Typography>
                  <TextField
                    fullWidth
                    label=""
                    name="comment"
                    margin="normal"
                    placeholder={t("form.commentPlaceholder")}
                    value={formik.values.comment}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.comment && Boolean(formik.errors.comment)
                    }
                    helperText={formik.touched.comment && formik.errors.comment}
                    multiline
                    rows={3}
                    sx={{
                      height: "104px",
                      "& .MuiInputBase-input": {
                        textAlign: "left", // Centrar texto dentro del área de texto
                        color: "black", // Cambiar color del texto ingresado
                        fontSize: "12px",
                        "&::placeholder": {
                          textAlign: "center",
                          color: "black", // Cambiar color del placeholder a negro
                          fontSize: "14px",
                          fontWeight: "400",
                          lineHeight: "28px",
                          letterSpacing: "0.15px",
                          opacity: 1, // Asegura que el color sea visible
                          fontFamily: "'Roboto' sans-serif",
                        },
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "1px dashed #d3d3d3", // Bordes entrecortados
                      },
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "8px", // Bordes redondeados
                        padding: "10px", // Espaciado interno
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          border: "1px dashed #d3d3d3", // Mantiene el mismo color al estar enfocado
                        },
                      },
                    }}
                  />
                  <Divider
                    sx={{ mb: 2, mt: 2, display: isMobile ? "block" : "none" }}
                  />
                  <Typography className="input-titles">
                    {t("form.shareInfo")}
                  </Typography>

                  <FormControlLabel
                    sx={{
                      mt: 4,
                      margin: "20px 0 0",
                      width: "100%",
                    }}
                    control={
                      <Checkbox
                        name="acceptTerms"
                        color="primary"
                        checked={formik.values.acceptTerms}
                        onChange={formik.handleChange}
                        icon={
                          <Box
                            sx={{
                              width: 20,
                              height: 20,
                              border: "2px solid #999",
                              borderRadius: "4px",
                            }}
                          />
                        }
                        checkedIcon={
                          <Box
                            sx={{
                              width: 25,
                              height: 25,
                              borderRadius: "4px",
                              backgroundColor: "#21386A",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              color: "white",
                              fontWeight: "bold",
                            }}
                          >
                            ✓
                          </Box>
                        }
                        sx={{
                          color: "primary.main",
                          marginLeft: "4%",
                          "& .MuiSvgIcon-root": {
                            fontSize: "24px", // Tamaño del checkbox
                          },
                        }}
                      />
                    }
                    label={
                      <Typography
                        sx={{
                          paddingLeft: "4%",
                          fontFamily: "'Montserrat', sans-serif",
                          fontWeight: "500",
                          fontSize: "12px",
                          lineHeight: "16px",
                          letterSpacing: "0.5px",
                          textAlign: "left",
                          margin: 0,
                          color: "black",
                          maxWidth: isMobile ? "260px" : "100%",
                          "& a": {
                            color: "#3880FF", // Color azul para los enlaces
                            textDecoration: "underline", // Sin subrayado

                            "&:hover": {
                              textDecoration: "underline", // Subrayado al pasar el cursor
                            },
                          },
                        }}
                      >
                        {t("form.termsText")}
                        <a
                          href="/terms"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {t("form.termsLink")}
                        </a>{" "}
                        {t("form.and")}
                        <a
                          href="/privacy"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {t("form.privacyLink")}
                        </a>{" "}
                        {t("form.ofThisApp")}
                      </Typography>
                    }
                  />

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      disabled={!isFormReadyToSubmit() || confirmOpen}
                      sx={{
                        mt: 5,
                        mb: 2,
                        width: "145px",
                        height: "56px",
                        textTransform: "none",
                        fontFamily: "'Montserrat', sans-serif",
                        fontWeight: "500",
                        fontSize: "18px",
                        lineHeight: "56px",
                        letterSpacing: "1.2px",
                        backgroundColor: "#21386A",
                        color: "white",
                        ":hover": {
                          backgroundColor: "#21386A",
                        },
                        "&.Mui-disabled": {
                          backgroundColor: "rgba(33, 56, 106, 0.5)", // azul con opacidad
                          color: "rgba(255, 255, 255, 0.7)", // blanco opaco
                        },
                        border: "none",
                        gap: "4px", // Espacio uniforme entre texto e ícono
                      }}
                    >
                      {t("form.submit")}
                      <SendIcon
                        sx={{
                          fontSize: "24px", // Tamaño del ícono
                          marginLeft: "5px", // Espacio entre texto e ícono
                          transform: "rotate(-45deg)", // Rotación del ícono
                          display: "inline-block", // Asegura la alineación con el texto
                          marginBottom: "8px",
                        }}
                      />
                    </Button>
                  </Box>
                </Box>
              </Box>
            )}
          </form>

          {/*Modal*/}
          <Modal
            open={confirmOpen}
            onClose={(event, reason) => {
              if (reason !== "backdropClick") {
                setConfirmOpen(false); // solo cerrar si NO fue por clic fuera del modal
              }
            }}
          >
            <Box
              sx={{
                ...modalStyle,
                borderRadius: "12px",
                boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.25)",
                position: "relative",
                padding: "24px 10px 32px 10px",
              }}
            >
              <Box
                component="img"
                src="/modal-close-icon.svg"
                alt="Cerrar"
                onClick={() => setConfirmOpen(false)}
                sx={{
                  position: "absolute",
                  top: 18,
                  right: 12,
                  width: 30,
                  height: 30,
                  cursor: "pointer",
                  zIndex: 2,
                  transition: "transform 0.2s",
                  "&:hover": {
                    transform: "scale(1.1)",
                  },
                }}
              />

              <Typography
                sx={{
                  fontFamily: "'Montserrat', sans-serif",
                  fontWeight: 700,
                  fontSize: "24px",
                  letterSpacing: "0px",
                }}
                textAlign="center"
              >
                {t("form.modal.title")}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  mt: 1,
                  mb: 3,
                  px: 1,
                  fontFamily: "'Montserrat', sans-serif",
                  fontWeight: 400,
                  fontSize: "14px",
                  letterSpacing: "0px",
                  color: "black",
                  textAlign: "center",
                }}
              >
                {t("form.modal.description")}
              </Typography>
              <Divider sx={{ mb: 2, mt: 2 }} />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  gap: 2,
                  mt: 3,
                }}
              >
                <Button
                  variant="contained"
                  onClick={handleConfirmSend}
                  sx={{
                    width: "145px",
                    height: "56px",
                    textTransform: "none",
                    fontFamily: "'Montserrat', sans-serif",
                    fontWeight: "500",
                    fontSize: "18px",
                    lineHeight: "56px",
                    letterSpacing: "1.2px",
                    backgroundColor: "#21386A",
                    color: "white",
                    ":hover": {
                      backgroundColor: "#21386A",
                    },
                    "&.Mui-disabled": {
                      backgroundColor: "rgba(33, 56, 106, 0.5)", // azul con opacidad
                      color: "rgba(255, 255, 255, 0.7)", // blanco opaco
                    },
                    border: "none",
                    gap: "4px", // Espacio uniforme entre texto e ícono
                  }}
                >
                  {t("form.submit")}
                  <SendIcon
                    sx={{
                      fontSize: "24px", // Tamaño del ícono
                      marginLeft: "5px", // Espacio entre texto e ícono
                      transform: "rotate(-45deg)", // Rotación del ícono
                      display: "inline-block", // Asegura la alineación con el texto
                      marginBottom: "8px",
                    }}
                  />
                </Button>
                <Button
                  variant="outlined"
                  sx={{
                    border: "1.5px solid red",
                    color: "red",
                    textTransform: "none",
                    fontSize: "18px",
                    fontFamily: "'Montserrat', sans-serif",
                    fontWeight: 500,
                    px: 3,
                    py: 1,
                    borderRadius: "4px",
                    "&:hover": {
                      backgroundColor: "#ffe5e5",
                      borderColor: "red",
                    },
                  }}
                  onClick={() => setConfirmOpen(false)}
                >
                  {t("form.modal.cancel")}
                </Button>
              </Box>
            </Box>
          </Modal>
        </Box>
      </Box>

      {/* Footer */}
      <Footer />
    </Box>
  );
};

export default Formulario;
